import styled from "styled-components";

import background from "../../../assets/img/background-login.jpg";

export const Container = styled.div`
    .slick-dots {
        bottom: 10px;
    }

    .slick-prev {
        z-index: 100;
        left: 10px;
    }

    .slick-next {
        right: 10px;
    }

    .slick-slide {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 750px) {
            padding: 2vh;
        }
    }

    .slick-dots {
        font-size: 1.5vh;
        button:before {
            color: #fff;
        }

        li.slick-active button:before {
            color: #fff;
        }
    }
`;

export const BackgroundLogin = styled.div`
    width: 50vw;
    height: 100vh;

    background: url(${background});

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 1rem;

    img {
        width: 100%;
        max-width: 420px;
    }
`;
