import { useCallback, useState } from "react";

import IPermissions from "../interfaces/permissions";

const usePermissions = () => {
    const initialPermissions = useCallback(
        () => ({
            application: {
                controllers: {
                    documents: {
                        findone: {
                            enabled: false,
                        },
                        find: {
                            enabled: false,
                        },
                        create: {
                            enabled: false,
                        },
                        update: {
                            enabled: false,
                        },
                        delete: {
                            enabled: false,
                        },
                    },
                },
            },
            "users-permissions": {
                controllers: {
                    user: {
                        find: { enabled: false },
                        create: { enabled: false },
                        findone: { enabled: false },
                        update: { enabled: false },
                        destroy: { enabled: false },
                    },
                },
            },
        }),
        []
    );

    const [permissions, setPermissions] = useState<IPermissions>(
        initialPermissions()
    );

    return { permissions, setPermissions };
};

export default usePermissions;
