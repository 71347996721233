import styled, { keyframes, css } from "styled-components";

interface Props {
    show?: boolean;
}

const animate = keyframes`
    from {
        width: var(--aside-width);
    } to {
        width: var(--aside-width-show);
    }
`;

export const Container = styled.aside<Props>`
    width: var(--aside-width);
    height: 100vh;
    background: var(--primary);

    position: fixed;
    z-index: 100;

    display: flex;
    flex-direction: column;

    ${(props) =>
        props.show &&
        css`
            animation: ${animate} 0.3s linear forwards !important;
        `}
`;

export const Links = styled.div``;

export const Bottom = styled.div`
    margin-top: auto;
`;
