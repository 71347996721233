import styled from "styled-components";

export default styled.label`
    display: flex;
    flex-direction: column;

    width: 100%;

    font-size: 1.1rem;
`;
