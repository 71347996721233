import React from "react";

import Viewer from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import { Container } from "./styles";

interface Props {
    url?: string;
}

const PDF: React.FC<Props> = ({ url }) => {
    return (
        <Container>
            {url && <Viewer fileUrl={url} defaultScale={0.5} />}
        </Container>
    );
};

export default PDF;
