import { useCallback } from "react";
import swal from "sweetalert";

import IError from "../interfaces/error";

const useNotification = () => {
    const confirm = (title: string, text: string, accept: () => void) => {
        swal({
            title,
            text,
            icon: "warning",
            buttons: ["Cancelar", "Remover"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                accept();
            }
            return;
        });
    };

    const success = (message: string) => {
        swal(message, {
            icon: "success",
        });
    };

    const error = useCallback(
        (message: string, errors?: any, error?: IError) => {
            if (errors && error && errors[error.id]) {
                swal(errors[error.id], {
                    icon: "error",
                });
                return;
            }

            swal(message, {
                icon: "error",
            });
        },
        []
    );

    return { confirm, success, error };
};

export default useNotification;
