import React from "react";

import Aside from "../Aside";

import { Container as Styled, Main } from "./styles";

const Container: React.FC = ({ children }) => {
    return (
        <Styled>
            <Aside />
            <Main>{children}</Main>
        </Styled>
    );
};

export default Container;
