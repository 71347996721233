import React from "react";

import { Container } from "./styles";

interface Props {
    show?: boolean;
    to: string;
    icon: JSX.Element;
    title?: string;
}

const Link: React.FC<Props> = ({ show, to, icon, title }) => {
    return (
        <Container {...{ to }}>
            <i>{icon}</i>

            {show && title && <span>{title}</span>}
        </Container>
    );
};

export default Link;
