import React from "react";
import { FaUser, FaPlus } from "react-icons/fa";

import AdminContainer from "../../components/Container";
import Table, { ResponsiveTable } from "../../components/Table";
import Button from "../../components/Button";
import Link from "../../components/Link";

import useUsers from "./useUsers";

import { Container, Header } from "./styles";

const Users: React.FC = () => {
    const { hasUserCreate } = useUsers();

    return (
        <AdminContainer>
            <Container>
                <Header>
                    <h1>Usuários</h1>

                    {hasUserCreate() && (
                        <Link to="/user">
                            <Button>
                                <i>
                                    <FaUser />
                                    <FaPlus />
                                </i>{" "}
                                Cadastrar usuário
                            </Button>
                        </Link>
                    )}
                </Header>

                <ResponsiveTable>
                    <Table id="users-table" style={{ width: "100%" }} />
                </ResponsiveTable>
            </Container>
        </AdminContainer>
    );
};

export default Users;
