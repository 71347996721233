import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";

import AdminContainer from "../../components/Container";
import Button from "../../components/Button";
import Form, { Item, Label, Input, Select, Error } from "../../components/Form";

import useUser from "./useUser";
import useAccess from "./useAccess";

import { Container, FormContainer } from "./styles";

const User: React.FC = () => {
    const {
        user,
        errors,
        handlerOnChange,
        onChange,
        handlerOnSubmit,
        hasUserDestroy,
        handlerRemove,
    } = useUser();

    const { accesses } = useAccess();

    const { id } = useParams<{ id: string }>();

    return (
        <AdminContainer>
            <Container>
                <header>
                    <h1>Usuário</h1>
                </header>
                <FormContainer>
                    <Form onSubmit={handlerOnSubmit}>
                        <Item>
                            <Label>
                                Nome social
                                <Input
                                    name="name"
                                    value={user.name}
                                    onChange={handlerOnChange}
                                    error={!!errors.name}
                                />
                                {errors.name && <Error>{errors.name}</Error>}
                            </Label>
                        </Item>
                        <Item>
                            <Label>
                                Nome de usuário
                                <Input
                                    name="username"
                                    value={user.username}
                                    onChange={handlerOnChange}
                                    error={!!errors.username}
                                />
                                {errors.username && (
                                    <Error>{errors.username}</Error>
                                )}
                            </Label>
                        </Item>
                        <Item>
                            <Label>
                                E-mail
                                <Input
                                    name="email"
                                    type="email"
                                    value={user.email}
                                    onChange={handlerOnChange}
                                    error={!!errors.email}
                                />
                                {errors.email && <Error>{errors.email}</Error>}
                            </Label>
                        </Item>
                        <Item>
                            <Label>
                                Acesso
                                <Select
                                    value={user.access}
                                    onChange={(value) => {
                                        onChange("access", value);
                                    }}
                                    options={accesses}
                                    error={!!errors.access}
                                />
                                {errors.access && (
                                    <Error>{errors.access}</Error>
                                )}
                            </Label>
                        </Item>
                        <Row>
                            <Col md="6" sm="12">
                                <Button type="submit">
                                    <i>
                                        <FaPaperPlane />
                                    </i>
                                    Enviar
                                </Button>
                            </Col>
                            {id && hasUserDestroy() && (
                                <Col md="6" sm="12">
                                    <Button
                                        onClick={handlerRemove}
                                        variant="outline"
                                        fill="var(--red)"
                                    >
                                        <i>
                                            <FaTrash />
                                        </i>
                                        Remover
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Form>
                </FormContainer>
            </Container>
        </AdminContainer>
    );
};

export default User;
