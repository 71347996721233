import api from "../services/api";

import useApi from "./useApi";

const useForm = () => {
    const { getOptions } = useApi();

    const uploadFile = async (file: any) => {
        let data = new FormData();
        data.append(`files`, file);

        const response: any[] = await api.post("/upload", data, getOptions());

        return response[0];
    };

    return { uploadFile };
};

export default useForm;
