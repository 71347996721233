import { css } from "styled-components";

import { Styled } from "../../components/Input";

export default css`
    .dataTables_wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .dataTables_length,
    .dataTables_filter,
    .dataTables_info {
        width: 50%;
        margin-bottom: 0.5rem;
    }

    .dataTables_length select {
        ${Styled}
    }

    .dataTables_filter input {
        ${Styled}
    }

    .dataTables_filter {
        display: flex;
        flex-direction: row-reverse;
    }

    .table {
        width: 100%;
        height: 65vh;
        overflow: scroll;

        margin-bottom: 0.5rem;

        @media (max-width: 763px) {
            height: 50vh;
        }
    }

    .dataTables_paginate,
    .dataTables_info {
        margin-top: 0;
    }

    .dataTables_paginate {
        margin-left: auto;
        margin-right: 2rem;
        display: flex;
        align-items: center;
    }

    .dataTables_paginate span {
        height: 100%;
        display: flex;
    }

    .paginate_button {
        cursor: pointer;
        text-decoration: none;
    }

    .paginate_button.previous,
    .paginate_button.next {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.2rem;
        color: var(--black);
    }

    .paginate_button.disabled {
        color: var(--gray);
    }

    .dataTables_paginate span a {
        height: 100%;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--black);
    }

    .paginate_button:hover {
        text-decoration: none;
    }

    .paginate_button.current {
        color: var(--primary);
    }

    @media (max-width: 750px) {
        .dataTables_paginate,
        .dataTables_length,
        .dataTables_filter,
        .dataTables_info {
            width: 100%;
        }

        .dataTables_filter {
            flex-direction: row;
        }
    }
`;
