import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;

    & span {
        font-weight: 600;
    }

    & span {
        height: auto;
    }
`;

export const DropContainer = styled.div.attrs({
    className: "dropzone",
})`
    width: 100%;
    height: 250px;

    border-radius: 2px;

    margin: 0 auto;
    cursor: pointer;

    background-color: var(--gray);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & .viewer-layout-container {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        border: 2px solid #dddd;
        box-shadow: var(--shadow-default);

        grid-template-rows: none;
        zoom: 0.5;
    }

    & .viewer-layout-container .viewer-layout-toolbar {
        display: none;
    }

    & .viewer-layout-main {
        overflow-x: hidden;
    }
`;

export const FileControl = styled.div`
    height: 32px;
    width: 100%;

    display: flex;

    font-size: 1.5rem;
`;

export const DeleteFile = styled.div`
    width: 50%;
    border: 2px solid var(--red);
    transition: 200ms;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    svg {
        fill: var(--red);
        font-size: 0.8rem;
    }

    &:hover {
        filter: brightness(130%);
    }

    &.file-disabled {
        display: none;
    }
`;

export const ViewFile = styled.div`
    width: 50%;

    cursor: pointer;

    background-color: var(--blue);
    color: var(--gray);
    transition: 200ms;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        filter: brightness(130%);
    }

    &.file-disabled {
        display: none;
    }

    svg {
        display: flex;
        justify-content: center;
        align-items: center;

        fill: var(--white);
        font-size: 1rem;
    }
`;
