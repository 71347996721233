import { useCallback, useEffect, useState } from "react";

import useNotification from "../../hooks/useNotification";
import useApi from "../../hooks/useApi";

import api from "../../services/api";

import { IAccess } from "./interfaces";

const useAccess = () => {
    const [accesses, setAccesses] = useState<any[]>([]);

    const { error } = useNotification();

    const { getOptions } = useApi();

    const show = useCallback(() => {
        const show = async () => {
            try {
                const { roles }: { roles: IAccess[] } = await api.get(
                    "/users-permissions/roles",
                    getOptions()
                );

                const serializedRoles = roles.map((role) => ({
                    value: role.id,
                    label: role.name,
                }));

                setAccesses(serializedRoles);
            } catch (err) {
                error("Listar acessos falhou");
            }
        };

        show();
    }, [error, getOptions]);

    useEffect(() => {
        show();
    }, [show]);

    return { accesses };
};

export default useAccess;
