import styled, { css } from "styled-components";

interface Props {
    variant?: "outline";
    fill?: string;
}

export default styled.button<Props>`
    display: flex;
    align-items: center;

    font-size: 1rem;

    background-color: ${(props) => props.fill || "var(--primary)"};
    color: var(--white);

    min-height: 40px;
    padding: 0 1rem;

    border: 2px solid ${(props) => props.fill || "var(--primary)"};
    border-radius: 5px;

    cursor: pointer;

    box-shadow: 1px 1px 3px -1px var(--black);

    transition: 0.2s;

    &:active {
        box-shadow: none;
        transform: scale(0.95);
    }

    i {
        display: flex;
        align-items: center;

        margin-right: 0.2rem;

        svg {
            fill: var(--white);
        }
    }

    ${(props) =>
        props.variant === "outline" &&
        css`
            color: ${(props: Props) => props.fill || "var(--primary)"};
            background-color: var(--white);

            i {
                svg {
                    fill: ${(props: Props) => props.fill || "var(--primary)"};
                }
            }

            &:hover {
                color: var(--white);
                background-color: ${(props: Props) =>
                    props.fill || "var(--primary)"};

                i {
                    svg {
                        fill: var(--white);
                    }
                }
            }
        `}
`;
