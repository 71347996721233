import { useContext, useState } from "react";

import PermissionsContext from "../../contexts/permissions";

const useAside = () => {
    const { permissions } = useContext(PermissionsContext);

    const [show, setShow] = useState(false);

    const handlerToggle = () => {
        setShow(!show);
    };

    const handlerOnMouseOver = () => {
        if (window.innerWidth < 763) return;

        setShow(true);
    };

    const handlerOnMouseLeave = () => {
        if (window.innerWidth < 763) return;

        setShow(false);
    };

    const documentIsAllowFind = () => {
        const {
            find: { enabled: find },
            findone: { enabled: findone },
            create: { enabled: create },
            update: { enabled: update },
            delete: { enabled: destroy },
        } = permissions.application.controllers.documents;

        return find && (findone || create || update || destroy);
    };

    return {
        show,
        handlerToggle,
        handlerOnMouseOver,
        handlerOnMouseLeave,
        documentIsAllowFind,
    };
};

export default useAside;
