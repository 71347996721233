import styled from "styled-components";

import { Link } from "react-router-dom";

export default styled(Link)`
    font-size: 0.8rem;

    color: var(--primary);

    filter: brightness(95%);

    transition: 0.2s;

    &:hover {
        filter: brightness(120%);
        color: var(--primary);
    }
`;
