import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;

    a {
        margin-left: auto;
    }

    p {
        font-size: 0.8rem;
    }

    button {
        margin: 1rem auto 0;
    }

    form {
        width: 100%;
        max-width: 400px;
    }
`;
