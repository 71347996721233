import styled from "styled-components";

export { default as Label } from "../Label";

export { default as Input } from "../Input";

export { default as Select } from "../Select";

export { default as Error } from "../Error";

export { default as File } from "../File";

export const Item = styled.div`
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;
`;

export default styled.form``;
