import axios from "axios";

import { IError } from "../interfaces/api";

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Authorization: localStorage.getItem("token"),
    },
});

api.interceptors.response.use(
    function (response) {
        return Promise.resolve(response.data);
    },
    function (error: IError) {
        const { data } = error.response;

        if (!("data" in data)) {
            return Promise.reject(data);
        }

        const { data: newData } = data;

        if (Array.isArray(newData)) {
            return Promise.reject(newData[0].messages[0]);
        }

        return Promise.reject(newData);
    }
);

export const publicRequest = {
    headers: {
        Authorization: "",
    },
};

export default api;
