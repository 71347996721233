import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import password from "generate-password";

import useNotification from "../../hooks/useNotification";
import loading from "../../hooks/useLoading";
import useApi from "../../hooks/useApi";

import PermissionsContext from "../../contexts/permissions";

import api from "../../services/api";

import IUser from "../../interfaces/user";

import configErrors from "../../config/errors/user";

interface User {
    name: string;
    username: string;
    email: string;
    access: null | any;
}

const useUser = () => {
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const { success, error, confirm } = useNotification();

    const { getOptions } = useApi();

    const { permissions } = useContext(PermissionsContext);

    const initialUser = useCallback(
        () => ({
            name: "",
            username: "",
            email: "",
            access: null,
        }),
        []
    );

    const initialErrors = useCallback(
        () => ({
            name: "",
            username: "",
            email: "",
            access: "",
        }),
        []
    );

    const [user, setUser] = useState<User>(initialUser());

    const [errors, setErrors] = useState(initialErrors());

    const show = useCallback(() => {
        const show = async () => {
            const idLoading = loading();

            const user: IUser = await api.get(`/users/${id}`, getOptions());

            const { name, username, email, role } = user;

            const serializedUser = {
                id,
                name,
                username,
                email,
                access: { value: role.id, label: role.name },
            };

            setUser(serializedUser);

            loading(idLoading);
        };

        show();
    }, [getOptions, id]);

    useEffect(() => {
        if (id) show();
    }, [id, show]);

    const handlerOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        onChange(name, value);
    };

    const onChange = (name: string, value: any) => {
        setUser({ ...user, [name]: value });
    };

    const handlerValidate = () => {
        let response = true;
        let errors = initialErrors();

        if (!user.name) {
            response = false;
            errors.name = "Preencha esse campo";
        }

        if (!user.username) {
            response = false;
            errors.username = "Preencha esse campo";
        }

        if (!user.email) {
            response = false;
            errors.email = "Preencha esse campo";
        }

        if (!user.access) {
            response = false;
            errors.access = "Preencha esse campo";
        }

        setErrors(errors);

        return response;
    };

    const handlerTemplateCreateMail = (username: string, password: string) => {
        return `
        <!DOCTYPE html>
        <html>
        <head>
            <style>
                .container {
                    position: relative;
                    width: 100%;
                }
                * {
                    margin: 0;
                    padding: 0;
                }
                h1 {
                    color: #38733e;
                    margin-bottom: 1rem;
                }
                .campo {
                    margin-bottom: 1rem;
                }
                .campo h2 {
                    color: #38733e;
                    margin-right: 0.1rem;
                    display: flex;
                }
                .campo span {
                    color: black;
                }
            </style>
        </head>
        <body>
            <p>Bem-vindo a o CProd Noordhen,<p>

            <p style="margin: 8px 0">Segue suas informações de acesso:</p>

            <div class="container">
                <div class="campo">
                    <h2>Nome de usuário</h2>
                    <span>${username}</span>
                </div>
                <div class="campo">
                    <h2>Senha</h2>
                    <span>${password}</span>
                </div>
            </div>

            <p>Para ir para o CProd Noordhen <a target="_blank" href="${window.location.origin}">clique aqui<a></p>
        </body>
    </html>`;
    };

    const create = async () => {
        const { username, name, email, access } = user;

        const newPassword = password.generate();

        const serializedUser = {
            username,
            name,
            email,
            role: access.value,
            password: newPassword,
        };

        await api.post("/users", serializedUser, getOptions());

        await api.post(
            "/email",
            {
                to: email,
                subject: "Bem-vindo á noordhen",
                html: handlerTemplateCreateMail(username, newPassword),
            },
            getOptions()
        );

        success("Usuário cadastrado");
    };

    const update = async () => {
        const { username, name, email, access } = user;

        const serializedUser = {
            username,
            name,
            email,
            role: access.value,
        };

        await api.put(`/users/${id}`, serializedUser, getOptions());

        success("Usuário editado");
    };

    const handlerOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const idLoading = loading();

        try {
            if (!handlerValidate()) {
                loading(idLoading);
                return;
            }

            if (!id) {
                await create();
            } else {
                await update();
            }

            loading(idLoading);

            history.push("/users");
        } catch (err) {
            error("Cadastrar usuário falhou", configErrors, err);

            loading(idLoading);
        }
    };

    const hasUserDestroy = useCallback(() => {
        const {
            findone: { enabled: findone },
            destroy: { enabled: destroy },
        } = permissions["users-permissions"].controllers.user;

        return findone && destroy;
    }, [permissions]);

    const handlerRemove = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.preventDefault();

        confirm(
            "Tem certeza que deseja remover o usuário?",
            "Uma vez excluído, você não poderá recuperar!",
            async () => {
                const idLoading = loading();
                try {
                    await api.delete(`/users/${id}`, getOptions());

                    success("Usuário removido");

                    loading(idLoading);

                    history.push("/users");
                } catch (err) {
                    error("Remover usuário falhou");

                    loading(idLoading);
                }
            }
        );
    };

    return {
        user,
        errors,
        handlerOnChange,
        onChange,
        handlerOnSubmit,
        hasUserDestroy,
        handlerRemove,
    };
};

export default useUser;
