import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import useNotification from "../../hooks/useNotification";
import loading from "../../hooks/useLoading";
import useForm from "../../hooks/useForm";
import useApi from "../../hooks/useApi";

import IDocument from "../../interfaces/document";

import PermissionsContext from "../../contexts/permissions";

import api from "../../services/api";

const useDocument = () => {
    const { id } = useParams<{ id: string }>();

    const { success, error, confirm } = useNotification();

    const { uploadFile } = useForm();

    const { getOptions } = useApi();

    const history = useHistory();

    const { permissions } = useContext(PermissionsContext);

    const initialDocument = useCallback(
        () => ({
            label: "",
            file: {
                preview: "",
            },
        }),
        []
    );

    const initialErrors = useCallback(
        () => ({
            label: "",
            file: "",
        }),
        []
    );

    const [document, setDocument] = useState(initialDocument());

    const [errors, setErrors] = useState(initialErrors());

    const handlerOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        onChange(name, value);
    };

    const onChange = (name: string, value: any) => {
        setDocument({ ...document, [name]: value });
    };

    const handlerOnUploadDocumentFile = async (value: any) => {
        const idLoading = loading();
        try {
            const file = value[0];

            const uploadedFile = await uploadFile(file);

            onChange("file", {
                ...uploadedFile,
                preview: `${process.env.REACT_APP_API}${uploadedFile.url}`,
            });

            loading(idLoading);
        } catch (err) {
            error("Cadastrar arquivo falhou");

            loading(idLoading);
        }
    };

    const handlerOnDeleteDocumentFile = () => {
        onChange("file", {
            preview: "",
        });
    };

    const isValidateLabel = async (label: string) => {
        const documents: { id: number }[] = await api.get(
            `/documents?label=${label}`,
            getOptions()
        );

        const document = documents[0];

        if (!document) return true;

        const isUpdate = parseInt(id) === document.id;

        return isUpdate;
    };

    const handlerValidate = async () => {
        let response = true;
        let errors = initialErrors();

        if (!document.label) {
            response = false;
            errors.label = "Preencha esse campo";
        } else {
            const validateLabel = await isValidateLabel(document.label);
            if (!validateLabel) {
                response = false;
                errors.label = "Rotulo já cadastrado";
            }
        }

        if (JSON.stringify(document.file) === '{"preview":""}') {
            response = false;
            errors.file = "Preencha esse campo";
        }

        setErrors(errors);

        return response;
    };

    const create = async () => {
        await api.post("/documents", document, getOptions());

        success("Documento cadastrado");
    };

    const update = async () => {
        await api.put(`/documents/${id}`, document, getOptions());

        success("Documento editado");
    };

    const handlerOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const idLoading = loading();

        try {
            const validate = await handlerValidate();
            if (!validate) {
                loading(idLoading);
                return;
            }

            if (!id) {
                await create();
            } else {
                await update();
            }

            loading(idLoading);

            history.push("/docs");
        } catch (err) {
            error("Cadastrar documento falhou");

            loading(idLoading);
        }
    };

    const show = useCallback(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const { label, file }: IDocument = await api.get(
                    `/documents/${id}`,
                    getOptions()
                );

                const serializedDoc = {
                    label,
                    file: {
                        ...file,
                        preview: `${process.env.REACT_APP_API}${file.url}`,
                    },
                };

                setDocument(serializedDoc);

                loading(idLoading);
            } catch (err) {
                error("Listar documento falhou");

                loading(idLoading);
            }
        };

        if (id) {
            show();
        }
    }, [error, getOptions, id]);

    useEffect(() => {
        show();
    }, [show]);

    const isAllowCreate = () => {
        const {
            findone: { enabled: findone },
            create: { enabled: create },
        } = permissions.application.controllers.documents;

        return !id && findone && create;
    };

    const isAllowDestroy = () => {
        const {
            delete: { enabled: destroy },
        } = permissions.application.controllers.documents;

        return !!id && destroy;
    };

    const isAllowUpdate = () => {
        const {
            update: { enabled: update },
        } = permissions.application.controllers.documents;

        return !!id && update;
    };

    const handlerRemove = useCallback(
        (id: string) => {
            confirm(
                "Tem certeza que deseja remover o documento?",
                "Uma vez excluído, você não poderá recuperar!",
                async () => {
                    const idLoading = loading();
                    try {
                        await api.delete(`/documents/${id}`, getOptions());

                        success("Documento removido");

                        loading(idLoading);

                        history.push("/docs");
                    } catch (err) {
                        error("Remover documento falhou");
                    }
                }
            );
        },
        [confirm, error, getOptions, history, success]
    );

    return {
        document,
        errors,
        handlerOnChange,
        onChange,
        handlerOnUploadDocumentFile,
        handlerOnDeleteDocumentFile,
        handlerOnSubmit,
        handlerRemove,
        isAllowCreate,
        isAllowDestroy,
        isAllowUpdate,
    };
};

export default useDocument;
