import { createContext } from "react";

import IPermissions from "../interfaces/permissions";

interface IPermissionsContext {
    permissions: IPermissions;
    setPermissions: React.Dispatch<React.SetStateAction<IPermissions>>;
}

export default createContext<IPermissionsContext>({
    permissions: {
        application: {
            controllers: {
                documents: {
                    find: {
                        enabled: false,
                    },
                    findone: {
                        enabled: false,
                    },
                    create: {
                        enabled: false,
                    },
                    update: {
                        enabled: false,
                    },
                    delete: {
                        enabled: false,
                    },
                },
            },
        },
        "users-permissions": {
            controllers: {
                user: {
                    find: { enabled: false },
                    create: { enabled: false },
                    findone: { enabled: false },
                    update: { enabled: false },
                    destroy: { enabled: false },
                },
            },
        },
    },
    setPermissions: () => {},
});
