import React from "react";

import AuthContainer from "../../components/_Auth/Container";

import Form, { Item, Label, Input, Error } from "../../components/Form";
import Button from "../../components/Button";

import useResetPassword from "./useResetPassword";

import { Container } from "./styles";

const Login: React.FC = () => {
    const { data, errors, handlerOnSubmit, handleOnChange } =
        useResetPassword();

    return (
        <AuthContainer>
            <Container>
                <h3>Recuperar senha</h3>

                <Form id="login-form" onSubmit={handlerOnSubmit}>
                    <Item>
                        <Label>
                            Senha
                            <Input
                                type="password"
                                name="password"
                                value={data.password}
                                onChange={handleOnChange}
                                error={!!errors.password}
                            />
                            {errors.password && (
                                <Error>{errors.password}</Error>
                            )}
                        </Label>
                    </Item>

                    <Item>
                        <Label>
                            Confirmar senha
                            <Input
                                type="password"
                                name="passwordConfirmation"
                                value={data.passwordConfirmation}
                                onChange={handleOnChange}
                                error={!!errors.passwordConfirmation}
                            />
                            {errors.passwordConfirmation && (
                                <Error>{errors.passwordConfirmation}</Error>
                            )}
                        </Label>
                    </Item>

                    <Item>
                        <Button type="submit">Enviar</Button>
                    </Item>
                </Form>
            </Container>
        </AuthContainer>
    );
};

export default Login;
