import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";

import AdminContainer from "../../components/Container";
import Button from "../../components/Button";
import Form, { Item, Label, Input, File, Error } from "../../components/Form";

import useDocument from "./useDocument";

import { Container, Header } from "./styles";
import { useParams } from "react-router-dom";

const Document: React.FC = () => {
    const {
        document,
        errors,
        handlerOnChange,
        handlerOnUploadDocumentFile,
        handlerOnDeleteDocumentFile,
        handlerOnSubmit,
        handlerRemove,
        isAllowCreate,
        isAllowDestroy,
        isAllowUpdate,
    } = useDocument();

    const { id } = useParams<{ id: string }>();

    return (
        <AdminContainer>
            <Container>
                <Header>
                    <h1>Documento</h1>
                </Header>
                <Form onSubmit={handlerOnSubmit}>
                    <Item>
                        <Label>
                            Rótulo
                            <Input
                                name="label"
                                value={document.label}
                                onChange={handlerOnChange}
                                error={!!errors.label}
                            />
                            {errors.label && <Error>{errors.label}</Error>}
                        </Label>
                    </Item>
                    <Item>
                        Arquivo
                        <File
                            file={document.file}
                            onUpload={handlerOnUploadDocumentFile}
                            onDelete={handlerOnDeleteDocumentFile}
                            deleteDisabled={!!id}
                        />
                        {errors.file && <Error>{errors.file}</Error>}
                    </Item>
                    <Item>
                        <Row>
                            {(isAllowCreate() || isAllowUpdate()) && (
                                <Col md="6" sm="12">
                                    <Button type="submit">
                                        <i>
                                            <FaPaperPlane />
                                        </i>
                                        Enviar
                                    </Button>
                                </Col>
                            )}
                            {isAllowDestroy() && (
                                <Col md="6" sm="12">
                                    <Button
                                        onClick={() => {
                                            handlerRemove(id);
                                        }}
                                        variant="outline"
                                        fill="var(--red)"
                                        type="button"
                                    >
                                        <i>
                                            <FaTrash />
                                        </i>
                                        Remover
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Item>
                </Form>
            </Container>
        </AdminContainer>
    );
};

export default Document;
