import { createGlobalStyle } from "styled-components";

import datatable from "./datatable";

export default createGlobalStyle`
    :root {
        --black: #121212;
        --white: #FAFAFA;
        --gray: #b2b2b2;
        --red: #f44336;
        --blue: #03a9f4;
        --green-dark: #28733d;

        --bg-input: #ffffff;

        --primary: var(--green-dark);

        --shadow: 1px 1px 3px -1px var(--black);

        --aside-width: 75px;
        --aside-width-show: 280px;
        
        font-size: 130%;
        color: var(--black);
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;

        color: #121212;

        font-family: 'Calibri', sans-serif;
    }

    html, body, #root {
        width: 100%;
        min-height: 100vh;
    }

    h1, h2, h3, h4, h5 h6 {
        margin-bottom: 1.2rem;
    }

    p {
        margin-bottom: 1rem;
    }

    ${datatable}
`;
