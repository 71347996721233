import { useCallback, useEffect } from "react";
import ReactDOM from "react-dom";

import loading from "../../hooks/useLoading";
import useDataTables from "../../hooks/useDataTables";
import useApi from "../../hooks/useApi";

import PDF from "../../components/PDF";

import IDocument from "../../interfaces/document";

import api from "../../services/api";

const useHome = () => {
    const { renderData } = useDataTables();

    const { getOptions } = useApi();

    const renderFiles = useCallback(() => {
        const filesEl = document.querySelectorAll("#docs-table .file");

        filesEl.forEach((fileEl) => {
            ReactDOM.render(
                <PDF url={fileEl.getAttribute("data-url")!} />,
                fileEl
            );
        });
    }, []);

    const show = useCallback(() => {
        const show = async () => {
            const idLoading = loading();
            try {
                const documents: IDocument[] = await api.get(
                    "/documents",
                    getOptions()
                );

                const serializedDocuments = documents.map((document) => {
                    const { id, label, file } = document;

                    const url = `${process.env.REACT_APP_API}${file.url}`;

                    return {
                        label,
                        file: `<a target="_blank" href="${url}"><div class='file' data-id="${id}" data-url="${url}"></div></a>`,
                    };
                });

                renderData(
                    "#docs-table",
                    serializedDocuments,
                    [
                        { title: "Rótulo", data: "label" },
                        { title: "Arquivo", data: "file" },
                    ],
                    () => {
                        renderFiles();
                    }
                );

                loading(idLoading);
            } catch (err) {
                // error("Listar documento falhou");
                loading(idLoading);
            }
        };

        show();
    }, [getOptions, renderData, renderFiles]);

    useEffect(() => {
        show();
    }, [show]);

    return {};
};

export default useHome;
