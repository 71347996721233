import styled from "styled-components";

export const Container = styled.div`
    a {
        margin-left: auto;
    }

    button {
        margin: 1rem auto 0;
    }
`;
