import { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import loading from "../../hooks/useLoading";
import useNotification from "../../hooks/useNotification";

import configErrors from "../../config/errors/login";

import api, { publicRequest } from "../../services/api";

import UserContext from "../../contexts/user";

import { Response } from "./interfaces";

const useLogin = () => {
    const history = useHistory();

    const { error } = useNotification();

    const { setJwt } = useContext(UserContext);

    const initialData = useCallback(
        () => ({ identifier: "", password: "" }),
        []
    );

    const initialErrors = useCallback(
        () => ({ identifier: "", password: "" }),
        []
    );

    const [data, setData] = useState(initialData());

    const [errors, setErrors] = useState(initialErrors());

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setData({ ...data, [name]: value });
    };

    const handlerValidate = () => {
        let response = true;
        let errors = initialErrors();

        if (!data.identifier) {
            response = false;
            errors.identifier = "Preencha esse campo";
        }

        if (!data.password) {
            response = false;
            errors.password = "Preencha esse campo";
        }

        setErrors(errors);

        return response;
    };

    const handlerLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const idLoading = loading();
        try {
            if (!handlerValidate()) {
                loading(idLoading);
                return;
            }

            const response: Response = await api.post(
                "/auth/local",
                data,
                publicRequest
            );

            const token = `Bearer ${response.jwt}`;

            if (setJwt) setJwt(token);

            localStorage.setItem("token", token);

            loading(idLoading);

            history.push("/");
        } catch (e) {
            error("Entrar no sistema falhou", configErrors, e);

            loading(idLoading);
        }
    };

    return { data, errors, handlerLogin, handleOnChange };
};

export default useLogin;
