import styled from "styled-components";
import { Table } from "react-bootstrap";

export const ResponsiveTable = styled.div`
    overflow-x: auto;
`;

const Index = styled(Table)`
    background-color: #fff;
    border-radius: 4px;

    thead {
        background-color: var(--primary);
    }

    thead tr,
    tbody tr {
        display: table;

        width: 100%;
        min-height: 40px;

        table-layout: fixed;
    }

    tbody {
        display: block;

        height: 100%;
        min-width: 800px;

        overflow: auto;
    }

    th:nth-child {
        margin: 0 auto;
    }

    th {
        color: var(--white);
    }

    td,
    th {
        padding: 0.5rem 0.5rem;
        vertical-align: middle;
    }

    .actions {
        display: flex;

        .action {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`;

export default Index;
