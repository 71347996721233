import React from "react";

import AuthContainer from "../../components/_Auth/Container";

import Form, { Item, Label, Input, Error } from "../../components/Form";
import Button from "../../components/Button";

import useForgotPassword from "./useForgotPassword";

import { Container } from "./styles";

const Login: React.FC = () => {
    const { data, errors, handlerOnSubmit, handleOnChange } =
        useForgotPassword();

    return (
        <AuthContainer>
            <Container>
                <Form id="login-form" onSubmit={handlerOnSubmit}>
                    <Item>
                        <h3>Esqueceu a senha?</h3>

                        <p>
                            Não se preocupe! Entre com o seu e-mail e vamos te
                            enviar um link para alterar sua senha.
                        </p>

                        <Label>
                            E-mail
                            <Input
                                type="email"
                                name="email"
                                value={data.email}
                                onChange={handleOnChange}
                                error={!!errors.email}
                            />
                            {errors.email && <Error>{errors.email}</Error>}
                        </Label>
                    </Item>

                    <Item>
                        <Button type="submit">Enviar</Button>
                    </Item>
                </Form>
            </Container>
        </AuthContainer>
    );
};

export default Login;
