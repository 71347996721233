import { useState } from "react";

import IUser from "../interfaces/user";

const useUser = () => {
    const [user, setUser] = useState<IUser | undefined>(undefined);

    const [jwt, setJwt] = useState<string | undefined>(undefined);

    return { user, setUser, jwt, setJwt };
};

export default useUser;
