import React from "react";

import AuthContainer from "../../components/_Auth/Container";

import Form, { Item, Label, Input, Error } from "../../components/Form";
import Link from "../../components/Link";
import Button from "../../components/Button";

import useLogin from "./useLogin";

import { Container } from "./styles";
import { useEffect } from "react";

const Login: React.FC = () => {
    const { data, errors, handlerLogin, handleOnChange } = useLogin();

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <AuthContainer>
            <Container>
                <Form id="login-form" onSubmit={handlerLogin}>
                    <Item>
                        <Label>
                            Nome de usuário
                            <Input
                                type="text"
                                name="identifier"
                                value={data.identifier}
                                onChange={handleOnChange}
                                error={!!errors.identifier}
                            />
                            {errors.identifier && (
                                <Error>{errors.identifier}</Error>
                            )}
                        </Label>
                    </Item>

                    <Item>
                        <Label>
                            Senha
                            <Input
                                type="password"
                                name="password"
                                value={data.password}
                                onChange={handleOnChange}
                                error={!!errors.password}
                            />
                            {errors.password && (
                                <Error>{errors.password}</Error>
                            )}
                        </Label>
                    </Item>

                    <Item>
                        <Link to="/forgot-password">Esqueceu sua senha?</Link>
                    </Item>

                    <Item>
                        <Button type="submit">Entrar</Button>
                    </Item>
                </Form>
            </Container>
        </AuthContainer>
    );
};

export default Login;
