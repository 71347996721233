import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";

const animate = keyframes`
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
`;

export const StyledContainer = css`
    height: var(--aside-width);

    display: flex;
    align-items: center;

    transition: 0.2s;

    background: var(--primary);

    cursor: pointer;

    &:hover {
        filter: brightness(120%);
    }

    i {
        width: var(--aside-width);
        height: var(--aside-width);
        flex: 0 0 var(--aside-width);

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 2rem;

        svg {
            fill: var(--white);
        }
    }

    span {
        white-space: nowrap;

        opacity: 0;
        animation: ${animate} 0.2s 0.3s linear forwards;
    }
`;

export const Container = styled(Link)`
    ${StyledContainer}

    text-decoration: none;

    span {
        color: var(--white);
    }
`;
