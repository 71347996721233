import ReactDOM from "react-dom";
import { uniqueId } from "lodash";

import Loading from "../components/_Loading/Loading";

export { default as Root } from "../components/_Loading/Root";

function loading(id?: string) {
    if (!id) {
        const id = uniqueId("loading_");
        const node = document.createElement("div");
        node.setAttribute("class", "loading-root-container");
        node.setAttribute("data-id", id);
        document.querySelector("#loading-root")?.appendChild(node);

        ReactDOM.render(<Loading />, node);
        return id;
    }

    const loadingContainer = document.querySelector(
        `.loading-root-container[data-id=${id}]`
    )!;
    document.querySelector("#loading-root")?.removeChild(loadingContainer);
}

export default loading;
