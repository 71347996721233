import { useCallback, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { FaFolder } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import PermissionsContext from "../../contexts/permissions";

import loading from "../../hooks/useLoading";
import useDataTables from "../../hooks/useDataTables";
import useNotification from "../../hooks/useNotification";
import useForm from "../../hooks/useForm";
import useApi from "../../hooks/useApi";

import File from "../../components/File";

import api from "../../services/api";

import IDocument from "../../interfaces/document";

import { ActionButton } from "./styles";

const useDocuments = () => {
    const history = useHistory();

    const { permissions } = useContext(PermissionsContext);

    const { renderData } = useDataTables();

    const { error } = useNotification();

    const { getOptions } = useApi();

    const { uploadFile } = useForm();

    const handlerGoToUser = useCallback(
        (id: string) => {
            history.push(`/doc/${id}`);
        },
        [history]
    );

    const isAllowFindOne = useCallback(() => {
        return permissions.application.controllers.documents.findone.enabled;
    }, [permissions]);

    const renderActionEdit = useCallback(
        (idRegister: string, idElement: string) => {
            if (!isAllowFindOne()) return;

            ReactDOM.render(
                <ActionButton
                    onClick={() => {
                        handlerGoToUser(idRegister);
                    }}
                >
                    <FaFolder fill="var(--blue)" />
                </ActionButton>,
                document.getElementById(idElement)
            );
        },
        [handlerGoToUser, isAllowFindOne]
    );

    const renderActions = useCallback(() => {
        const actions = document.querySelectorAll(".action");

        actions.forEach((action) => {
            const { id } = action;

            switch (action.getAttribute("data-action")) {
                case "edit":
                    renderActionEdit(action.getAttribute("data-id")!, id);

                    break;

                default:
                    throw new Error("Invalid data-action");
            }
        });
    }, [renderActionEdit]);

    const handlerUpdateFile = useCallback(
        (value: any, id: string, show: Function) => {
            const handlerUpdateFile = async () => {
                const idLoading = loading();
                try {
                    const uploadedFile = await uploadFile(value[0]);

                    await api.put(
                        `/documents/${id}`,
                        { file: uploadedFile },
                        getOptions()
                    );

                    loading(idLoading);

                    show();
                } catch (error) {
                    error("Alterar arquivo de documento falhou");

                    loading(idLoading);
                }
            };

            handlerUpdateFile();
        },
        [getOptions, uploadFile]
    );

    const renderFiles = useCallback(
        (show: Function) => {
            const filesEl = document.querySelectorAll("#docs-table .file");

            filesEl.forEach((fileEl) => {
                const id = fileEl.getAttribute("data-id");

                ReactDOM.render(
                    <File
                        file={{ preview: fileEl.getAttribute("data-url") }}
                        deleteDisabled={true}
                        onUpload={(value: any) => {
                            handlerUpdateFile(value, id!, show);
                        }}
                    />,
                    fileEl
                );
            });
        },
        [handlerUpdateFile]
    );

    const show = useCallback(() => {
        const show = async () => {
            const idLoading = loading();
            try {
                const documents: IDocument[] = await api.get(
                    "/documents",
                    getOptions()
                );

                const serializedDocuments = documents.map((document) => {
                    const { id, label, file } = document;

                    return {
                        label,
                        file: `<div class='file' data-id="${id}" data-url="${process.env.REACT_APP_API}${file.url}"></div>`,
                        actions: `<div class="actions"><div class="action" data-action="edit" data-id="${id}" id="action-edit-${id}"></div>`,
                    };
                });

                renderData(
                    "#docs-table",
                    serializedDocuments,
                    [
                        { title: "Rótulo", data: "label" },
                        { title: "Arquivo", data: "file" },
                        { title: "", data: "actions" },
                    ],
                    () => {
                        renderActions();

                        renderFiles(show);
                    }
                );

                loading(idLoading);
            } catch (err) {
                error("Listar documentos falhou");
                loading(idLoading);
            }
        };

        show();
    }, [error, getOptions, renderActions, renderData, renderFiles]);

    useEffect(() => {
        show();
    }, [show]);

    const isAllowCreate = () => {
        const {
            findone: { enabled: findone },
            create: { enabled: create },
        } = permissions.application.controllers.documents;

        return findone && create;
    };

    return { isAllowCreate };
};

export default useDocuments;
