import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 1rem;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 763px) {
        flex-direction: column;

        margin-bottom: 0.5rem;
    }
`;

export const ActionButton = styled.button`
    background: transparent;
    border: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 1.2rem;
    }
`;
