import React from "react";
import { FaUser, FaPlus } from "react-icons/fa";

import AdminContainer from "../../components/Container";
import Table, { ResponsiveTable } from "../../components/Table";
import Button from "../../components/Button";
import Link from "../../components/Link";

import useDocuments from "./useDocuments";

import { Container, Header } from "./styles";

const Users: React.FC = () => {
    const { isAllowCreate } = useDocuments();

    return (
        <AdminContainer>
            <Container>
                <Header>
                    <h1>Documentos</h1>

                    {isAllowCreate() && (
                        <Link to="/doc">
                            <Button>
                                <i>
                                    <FaUser />
                                    <FaPlus />
                                </i>
                                Cadastrar documento
                            </Button>
                        </Link>
                    )}
                </Header>

                <ResponsiveTable>
                    <Table id="docs-table" style={{ width: "100%" }} />
                </ResponsiveTable>
            </Container>
        </AdminContainer>
    );
};

export default Users;
