import styled, { css } from "styled-components";

interface Props {
    error?: boolean;
}

export const Styled = css<Props>`
    height: 40px;

    padding: 0 0.5rem;

    background-color: var(--bg-input);

    border: 2px solid var(--bg-input);
    border-radius: 5px;

    box-shadow: var(--shadow);

    font-size: 1rem;

    transition: 0.2s;

    ${(props) =>
        props.error &&
        css`
            border-color: var(--red);
        `}
`;

export default styled.input`
    ${Styled}
`;
