import { createContext } from "react";

import IUser from "../interfaces/user";

interface IUserContext {
    user?: IUser;
    setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
    jwt?: string;
    setJwt?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default createContext<IUserContext>({
    user: undefined,
    setUser: () => {},
});
