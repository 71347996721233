import { useCallback, useContext } from "react";
import UserContext from "../contexts/user";

const useApi = () => {
    const { jwt } = useContext(UserContext);

    const getOptions = useCallback(
        () => ({
            headers: {
                Authorization: localStorage.getItem("token") || jwt,
            },
        }),
        [jwt]
    );

    return { getOptions };
};

export default useApi;
