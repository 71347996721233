import React, { useContext } from "react";
import { FaHome, FaSignOutAlt, FaUser, FaFile } from "react-icons/fa";

import PermissionsContext from "../../contexts/permissions";

import Link from "../_Aside/Link";
import Toggle from "../_Aside/Toggle";

import useAside from "./useAside";

import { Container, Links, Bottom } from "./styles";

const Aside: React.FC = () => {
    const { permissions } = useContext(PermissionsContext);

    const {
        "users-permissions": {
            controllers: {
                user: {
                    find: { enabled: users },
                },
            },
        },
    } = permissions!;

    const {
        show,
        handlerToggle,
        handlerOnMouseOver,
        handlerOnMouseLeave,
        documentIsAllowFind,
    } = useAside();

    return (
        <Container
            {...{ show }}
            onMouseOver={handlerOnMouseOver}
            onMouseLeave={handlerOnMouseLeave}
        >
            <Links>
                <Link
                    {...{ show }}
                    to="/"
                    icon={<FaHome />}
                    title="Página principal"
                />
            </Links>

            <Links>
                {users && (
                    <Link
                        {...{ show }}
                        to="/users"
                        icon={<FaUser />}
                        title="Usuários"
                    />
                )}

                {documentIsAllowFind() && (
                    <Link
                        {...{ show }}
                        to="/docs"
                        icon={<FaFile />}
                        title="Documentos"
                    />
                )}
            </Links>

            <Bottom>
                <Link
                    {...{ show }}
                    to="/auth"
                    icon={<FaSignOutAlt />}
                    title="Sair"
                />
                <Toggle {...{ show }} onClick={handlerToggle} />
            </Bottom>
        </Container>
    );
};

export default Aside;
