import React from "react";
import Viewer from "@phuocng/react-pdf-viewer";
import Dropzone from "react-dropzone";
import { FaFilePdf, FaTrash, FaEye } from "react-icons/fa";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import {
    Container,
    DropContainer,
    FileControl,
    DeleteFile,
    ViewFile,
} from "./styles";

const File = (props: any) => {
    return (
        <Container>
            <Dropzone accept="application/pdf" onDropAccepted={props.onUpload}>
                {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                }) => (
                    <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                    >
                        {props.file.preview ? (
                            <>
                                <Viewer
                                    fileUrl={props.file.preview}
                                    defaultScale={0.5}
                                />
                            </>
                        ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center ">
                                <FaFilePdf className="empty-file" size="64" />
                                <span>
                                    Arraste e solte <br /> ou clique aqui
                                </span>
                            </div>
                        )}
                        <input {...getInputProps()} />
                    </DropContainer>
                )}
            </Dropzone>
            <FileControl>
                <DeleteFile
                    className={
                        props.deleteDisabled || !props.file.preview
                            ? "file-disabled"
                            : ""
                    }
                    onClick={props.onDelete}
                >
                    <FaTrash />
                </DeleteFile>
                <ViewFile
                    className={!props.file.preview ? "file-disabled" : ""}
                >
                    <a
                        href={props.file.preview}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaEye />
                    </a>
                </ViewFile>
            </FileControl>
        </Container>
    );
};

export default File;
