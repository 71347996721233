import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
`;

export const CarouselContainer = styled.div`
    width: 50%;
    height: 100vh;

    @media (max-width: 763px) {
        display: none;
    }
`;

export const Main = styled.main`
    width: 50%;
    height: 100vh;

    background: var(--white);

    @media (max-width: 763px) {
        width: 100%;
    }
`;

export const Header = styled.header`
    height: 15vh;

    display: flex;
    align-items: center;
    padding: 0 1rem;
`;

export const Section = styled.section`
    height: 85vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
