import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from "../../../assets/img/logo.svg";

import config from "./config.json";

import { Container, BackgroundLogin } from "./styles";

const Carousel: React.FC = () => {
    return (
        <Container>
            <Slider {...config}>
                <div>
                    <BackgroundLogin>
                        <img src={logo} alt="Noordhen CProd" />
                    </BackgroundLogin>
                </div>
            </Slider>
        </Container>
    );
};

export default Carousel;
