import styled from "styled-components";

export const Container = styled.div`
    width: 200px;
    height: 250px;

    & .viewer-layout-container {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        border: 2px solid #dddd;
        box-shadow: var(--shadow-default);

        grid-template-rows: none;
        zoom: 0.5;
    }

    & .viewer-layout-container .viewer-layout-toolbar {
        display: none;
    }

    & .viewer-layout-main {
        overflow-x: hidden;
    }
`;
