import React from "react";

import AdminContainer from "../../components/Container";
import Table, { ResponsiveTable } from "../../components/Table";

import useHome from "./useHome";

import { Container, Header } from "./styles";

const Home: React.FC = () => {
    useHome();

    return (
        <AdminContainer>
            <Container>
                <Header>
                    <h1>Noordhen Cprod</h1>
                </Header>

                <ResponsiveTable>
                    <Table id="docs-table" />
                </ResponsiveTable>
            </Container>
        </AdminContainer>
    );
};

export default Home;
