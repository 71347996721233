import styled, { keyframes, css } from "styled-components";

import { Props } from "./index";

import { StyledContainer } from "../Link/styles";

const animate = keyframes`
    to {
        transform: rotate(-180deg);
    }
`;

export const Container = styled.div<Props>`
    ${StyledContainer}

    display: none;

    @media (max-width: 763px) {
        display: flex;
    }

    ${(props) =>
        props.show &&
        css`
            i {
                animation: ${animate} 0.3s linear forwards;
            }
        `}
`;
