import React from "react";
import ReactSelect, { OptionsType, ActionMeta } from "react-select";

export interface OptionProps {
    value: string | number;
    label: string;
}

interface Props {
    options?: any[];
    isSearchable?: boolean;
    onChange?: (
        value: OptionProps | OptionsType<OptionProps> | null,
        actionMeta: ActionMeta<OptionProps>
    ) => void;
    value?: OptionProps | null;
    disabled?: boolean;
    placeholder?: string;
    isMulti?: boolean;
    error?: boolean;
}

const Select: React.FC<Props> = (props) => {
    return (
        <ReactSelect
            onChange={props.onChange}
            value={props.value}
            isSearchable={!!props.isSearchable}
            options={props.options}
            placeholder={props.placeholder || "Selecione..."}
            noOptionsMessage={() => "Sem opção"}
            isDisabled={props.disabled}
            isMulti={props.isMulti}
            styles={{
                control: (provided: any, state: any) => ({
                    ...provided,
                    borderRadius: "2px",
                    border: !props.error
                        ? "2px solid #fff"
                        : "2px solid var(--red)",
                    boxShadow: "var(--shadow)",
                    height: "40px",
                    ":hover": {
                        border: !props.error
                            ? "2px solid #fff"
                            : "2px solid var(--red)",
                    },
                }),
                menu: (provided: any, state: any) => ({
                    ...provided,
                    marginTop: 0,
                    border: 0,
                    outline: 0,
                    boxShadow: "var(--shadow)",
                    padding: 0,
                }),
                option: (provided: any, state: any) => ({
                    ...provided,
                    height: "40px",
                    backgroundColor: state.isSelected
                        ? "var(--primary)"
                        : "transparent",

                    "&:hover": {
                        backgroundColor: "var(--primary)",
                        color: "var(--white)",
                    },

                    "&:disabled": {
                        backgroundColor: "rgba(0,0,0,0.3)",
                    },
                }),
            }}
        />
    );
};

export default Select;
