import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import GlobalStyle from "./assets/styles/global";

import Routes from "./routes";
import { Worker } from "@phuocng/react-pdf-viewer";

import UserContext from "./contexts/user";
import PermissionsContext from "./contexts/permissions";

import { Root as LoadingRoot } from "./hooks/useLoading";

import useUser from "./hooks/useUser";
import usePermissions from "./hooks/usePermissions";

function App() {
    const { user, setUser, jwt, setJwt } = useUser();
    const { permissions, setPermissions } = usePermissions();

    return (
        <>
            <GlobalStyle />
            <LoadingRoot />
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                <UserContext.Provider value={{ user, setUser, jwt, setJwt }}>
                    <PermissionsContext.Provider
                        value={{ permissions, setPermissions }}
                    >
                        <Routes />
                    </PermissionsContext.Provider>
                </UserContext.Provider>
            </Worker>
        </>
    );
}

export default App;
