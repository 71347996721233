import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
`;

export const FormContainer = styled.div`
    form {
        width: 100%;
        max-width: 320px;

        margin: 0 auto;

        button {
            @media (max-width: 763px) {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }
`;
