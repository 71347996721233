import { useCallback, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { FaFolder } from "react-icons/fa";
import { useHistory } from "react-router-dom";

import useDataTables from "../../hooks/useDataTables";
import loading from "../../hooks/useLoading";
import useNotification from "../../hooks/useNotification";
import useApi from "../../hooks/useApi";

import PermissionsContext from "../../contexts/permissions";

import api from "../../services/api";

import IUser from "../../interfaces/user";

import { ActionButton } from "./styles";

const useUsers = () => {
    const history = useHistory();

    const { renderData } = useDataTables();

    const { error } = useNotification();

    const { permissions } = useContext(PermissionsContext);

    const { getOptions } = useApi();

    const hasUserCreate = useCallback(() => {
        const {
            create: { enabled: create },
        } = permissions["users-permissions"].controllers.user;

        return create;
    }, [permissions]);

    const hasUserUpdate = useCallback(() => {
        const {
            findone: { enabled: findone },
            update: { enabled: update },
        } = permissions["users-permissions"].controllers.user;

        return findone && update;
    }, [permissions]);

    const handlerGoToUser = useCallback(
        (id: string) => {
            history.push(`/user/${id}`);
        },
        [history]
    );

    const renderActionEdit = useCallback(
        (idRegister: string, idElement: string) => {
            if (!hasUserUpdate()) return;

            ReactDOM.render(
                <ActionButton
                    onClick={() => {
                        handlerGoToUser(idRegister);
                    }}
                >
                    <FaFolder fill="var(--blue)" />
                </ActionButton>,
                document.getElementById(idElement)
            );
        },
        [handlerGoToUser, hasUserUpdate]
    );

    const renderActions = useCallback(() => {
        const actions = document.querySelectorAll(".action");

        actions.forEach((action) => {
            const { id } = action;

            switch (action.getAttribute("data-action")) {
                case "edit":
                    renderActionEdit(action.getAttribute("data-id")!, id);

                    break;

                default:
                    throw new Error("Invalid data-action");
            }
        });
    }, [renderActionEdit]);

    const show = useCallback(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const users: IUser[] = await api.get("/users", getOptions());

                const serializedUsers = users.map((user) => {
                    const {
                        id,
                        name,
                        email,
                        role: { name: role },
                    } = user;

                    return {
                        name,
                        role,
                        email,
                        actions: `<div class="actions"><div class="action" data-action="edit" data-id="${id}" id="action-edit-${id}"></div>`,
                    };
                });

                let columns = [
                    { title: "Nome", data: "name" },
                    { title: "E-Mail", data: "email" },
                    { title: "Acesso", data: "role" },
                    { title: "", data: "actions" },
                ];

                renderData("#users-table", serializedUsers, columns, () => {
                    renderActions();
                });

                loading(idLoading);
            } catch (err) {
                error("Listar usuários falhou");
                loading(idLoading);
            }
        };

        show();
    }, [error, getOptions, renderActions, renderData]);

    useEffect(() => {
        show();
    }, [show]);

    return { hasUserCreate };
};

export default useUsers;
