import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import loading from "../../hooks/useLoading";
import useNotification from "../../hooks/useNotification";

import api, { publicRequest } from "../../services/api";

const useLogin = () => {
    const history = useHistory();

    const { success, error } = useNotification();

    const initialData = useCallback(
        () => ({
            email: "",
        }),
        []
    );

    const initialErrors = useCallback(
        () => ({
            email: "",
        }),
        []
    );

    const [data, setData] = useState(initialData());

    const [errors, setErrors] = useState(initialErrors());

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setData({ ...data, [name]: value });
    };

    const handlerValidate = () => {
        let response = true;
        let errors = initialErrors();

        if (!data.email) {
            response = false;
            errors.email = "Preencha esse campo";
        }

        setErrors(errors);

        return response;
    };

    const handlerOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const idLoading = loading();
        try {
            if (!handlerValidate()) {
                loading(idLoading);
                return;
            }

            await api.post("/auth/forgot-password", data, publicRequest);

            loading(idLoading);

            success("E-mail de recuperação de senha enviado");

            history.push("/auth");
        } catch (e) {
            error("Recuperar senha falhou");

            loading(idLoading);
        }
    };

    return { data, errors, handlerOnSubmit, handleOnChange };
};

export default useLogin;
