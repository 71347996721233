import React from "react";

import Carousel from "../Carousel";

import { Container, Main, Header, Section, CarouselContainer } from "./styles";

const AuthContainer: React.FC = ({ children }) => {
    return (
        <Container>
            <CarouselContainer>
                <Carousel />
            </CarouselContainer>
            <Main>
                <Header>
                    <h1>Noordhen CProd</h1>
                </Header>
                <Section>{children}</Section>
            </Main>
        </Container>
    );
};

export default AuthContainer;
