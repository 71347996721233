import React from "react";
import { FaChevronRight } from "react-icons/fa";

import { Container } from "./styles";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    show?: boolean;
}

const Toggle: React.FC<Props> = (props) => {
    return (
        <Container {...props}>
            <i>
                <FaChevronRight />
            </i>
        </Container>
    );
};

export default Toggle;
