import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;

    form {
        width: 100%;
        max-width: 400px;

        margin: 0 auto;

        button {
            margin: 0 auto;
        }
    }
`;

export const Header = styled.header``;
