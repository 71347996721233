import { useState, useEffect, useContext, useCallback } from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import loading from "./hooks/useLoading";

import UserContext from "./contexts/user";
import PermissionsContext from "./contexts/permissions";

import IUser from "./interfaces/user";

import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

import Users from "./pages/Users";
import User from "./pages/User";

import Documents from "./pages/Documents";
import Document from "./pages/Document";

import api from "./services/api";

import IPermissions from "./interfaces/permissions";

interface Response {
    role: {
        permissions: IPermissions;
    };
}

const Authenticated = ({ component: Component, ...rest }: any) => {
    const [status, setStatus] = useState(true);

    const { setUser, jwt } = useContext(UserContext);
    const { setPermissions } = useContext(PermissionsContext);

    const hasToken = useCallback(() => {
        console.log(jwt);

        return jwt || localStorage.getItem("token");
    }, [jwt]);

    const isAuthenticated = useCallback(() => {
        const isAuthenticated = async () => {
            const idLoading = loading();
            try {
                if (!hasToken()) {
                    setStatus(false);
                    loading(idLoading);
                    return;
                }

                const user: IUser = await api.get("/users/me", {
                    headers: {
                        Authorization: localStorage.getItem("token") || jwt,
                    },
                });

                const {
                    role: { permissions },
                }: Response = await api.get(
                    `/users-permissions/roles/${user.role.id}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token") || jwt,
                        },
                    }
                );

                setPermissions(permissions);
                setUser(user);

                setStatus(true);
                loading(idLoading);
            } catch (error) {
                loading(idLoading);
                setStatus(false);
            }
        };

        isAuthenticated();
    }, [hasToken, jwt, setPermissions, setUser]);

    useEffect(() => {
        isAuthenticated();
    }, [isAuthenticated]);

    return (
        <Route
            {...rest}
            render={(props) =>
                status ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Authenticated path="/" exact component={Home} />

            <Authenticated path="/users" exact component={Users} />
            <Authenticated path="/user" exact component={User} />
            <Authenticated path="/user/:id" exact component={User} />

            <Authenticated path="/docs" exact component={Documents} />
            <Authenticated path="/doc" exact component={Document} />
            <Authenticated path="/doc/:id" exact component={Document} />

            <Route path="/auth" exact component={Login} />
            <Route path="/forgot-password" exact component={ForgotPassword} />
            <Route path="/reset-password" exact component={ResetPassword} />
            <Route path="*" component={NotFound} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
