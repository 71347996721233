import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;

    position: relative;
`;

export const Main = styled.main`
    width: 100%;
    min-height: 100vh;

    padding-left: var(--aside-width);

    background: var(--white);
`;
